import React, { useEffect } from 'react';
import { LayoutProps } from '../layout/DefaultPageLayout';
import { getOs } from '../service/DeviceInfoService';

export const CommunityLinkFallback = (props: LayoutProps) => {
  useEffect(() => {
    const os = getOs();
    if (os === 'android') {
      const current = new URL(window.location.href);
      const id = current.searchParams.get('id');

      if (id) {
        const host = window.location.host;
        window.location.replace(`link://${host}/app/community?id=${id}`);
      }
    }
  }, []);

  return <></>;
};
